import testConfig from './testConfig'

const DATE_FORMAT = 'DD.MM.YYYY'

const ChargerCategory = {
    company: 'company',
    home: 'home'
}

const Roles = {
    payer: 'payer',
    driver: 'driver',
    fleetManager: 'fleet-manager',
    facilityManager: 'facility-manager',
    fleetHomeChargingManager: 'fleet-home-charging-manager'
}

const availableLanguages = ['en', 'sk', ' pl', 'hr']

const Lang = {
    sk: 'sk',
    en: 'en',
    pl: 'pl',
    hr: 'hr'
}

const Language = {
    english: 'English',
    poland: 'Polski',
    slovak: 'Slovenčina',
    croatian: 'Hrvatski'
}

const Locale = {
    en: 'en',
    sk: 'sk',
    pl: 'pl',
    hr: 'hr'
}

const Country = {
    Slovakia: 'Slovakia',
    Poland: 'Poland',
    Croatia: 'Croatia'
}

const CountryISO = {
    Slovakia: 'SVK',
    Poland: 'POL',
    Croatia: 'HRV'
}

const ChargingStatus = {
    Charging: 'charging',
    Cancelled: 'cancelled',
    Error: 'error',
    Finished: 'finished',
    SimultaniousChargingDenied: 'simultaniouschargingdenied'
}

const DriverStatus = {
    ok: 'OK'
}

const isMemberState = {
    1: true,
    0: false
}

const ChargingCommandName = {
    StartCharging: 'startcharging',
    StopCharging: 'stopcharging'
}

const Status = {
    Active: 'active'
}

const Client = {
    isCompany: 'company',
    isIndividual: 'individual',
    isSelfEmployed: 'self_employed'
}

export {
    testConfig,
    Lang,
    Roles,
    Locale,
    Country,
    CountryISO,
    Language,
    DATE_FORMAT,
    DriverStatus,
    isMemberState,
    ChargingStatus,
    ChargerCategory,
    availableLanguages,
    ChargingCommandName,
    Status,
    Client
}
