<script setup>
import { computed, nextTick, onBeforeMount, onMounted, onUpdated, reactive } from 'vue'
import store from '@/store'
import router from '@/router'
import { useRoute } from 'vue-router'

// composables
import useResolveTranslations from '@/composables/useResolveTranslations'

const { resolveTranslations } = useResolveTranslations()

import Usercentrics from '@usercentrics/cmp-browser-sdk'

// Cookies
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

// configs
import { Lang } from '@/configs/index.js'

// modules
import { host, env, ID } from '@/modules/unleash_config'
import { initializeZoho } from '@/modules/zoho'

// helpers
import useWindowWidth from '@/composables/windowWidth'

// components
import Notify from '@/components/Notify.vue'

const { dispatch, commit, getters } = store
const UC = new Usercentrics(import.meta.env.VITE_APP_COOKIE_BOT_ID)
const route = useRoute()

const props = defineProps({
    urlLang: {
        required: false,
        type: String,
        default: () => {
            return null
        }
    }
})

const { windowWidth } = useWindowWidth()

const state = reactive({
    host,
    env,
    ID,
    isToTop: false,
    isMouseOver: false,
    isLocationMap: false
})

const cssVariables = computed(() => {
    let themeVariables = ''
    const theme = store.state.themes.light
    Object.keys(theme).forEach((key) => {
        themeVariables = `${themeVariables} --v-${key}: ${theme[key]}; \n`
    })

    return `
        box-sizing: border-box;
        --font-extra-small-size: ${store.state.themes.fontSize.default['font-extra-small-size']};
        --font-small-size: ${store.state.themes.fontSize.default['font-small-size']};
        --font-small-plus-size: ${store.state.themes.fontSize.default['font-small-plus-size']};
        --font-base-size: ${store.state.themes.fontSize.default['font-base-size']};
        --font-middle-size: ${store.state.themes.fontSize.default['font-middle-size']};
        --font-large-size: ${store.state.themes.fontSize.default['font-large-size']};
        --font-extra-large-size: ${store.state.themes.fontSize.default['font-extra-large-size']};
        --font-x-extra-large-size: ${store.state.themes.fontSize.default['font-x-extra-large-size']};
        --font-xx-extra-large-size: ${store.state.themes.fontSize.default['font-xx-extra-large-size']};
        --font-xxx-extra-large-size: ${store.state.themes.fontSize.default['font-xxx-extra-large-size']};
        ${themeVariables}
    `
})

const sidebarExpanded = computed(() => {
    return store.state.sidebarExpanded
})

const cookiesExpiration = computed({
    get() {
        return store.state.settings.cookiesExpiration
    },
    set(value) {
        store.commit('settings/setFieldValue', {
            field: 'cookiesExpiration',
            value
        })
    }
})

const setDefaultCookiesSettings = () => {
    cookiesExpiration.value = import.meta.env.VITE_APP_COOKIES_DEFAULT_EXPIRATION_TIME || '24h'
}

const setCaptchaLang = (lang) => {
    const container = document.getElementById('captcha_container')
    if (container) {
        // Get GoogleCaptcha iframe
        const iframeGoogleCaptcha = container.querySelector('iframe')

        // Get language code from iframe
        const actualLang = iframeGoogleCaptcha
            .getAttribute('src')
            .match(/hl=(.*?)&/)
            .pop()

        // For setting new language
        if (actualLang !== lang) {
            iframeGoogleCaptcha.setAttribute(
                'src',
                iframeGoogleCaptcha.getAttribute('src').replace(/hl=(.*?)&/, 'hl=' + lang + '&')
            )
        }
    }
}

const onScroll = (e) => {
    if (typeof window === 'undefined') return
    const top = window.pageYOffset || e.target.scrollTop || 0
    state.isToTop = top > 200
}

const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

onUpdated(() => {
    state.isLocationMap = window.location.pathname.toLowerCase().indexOf('locationmap') > -1
})

onBeforeMount(async () => {
    if (props.urlLang) {
        const supportedLanguages = [Lang.en, Lang.sk, Lang.pl, Lang.hr]

        let lang = props.urlLang.toLowerCase()
        if (!supportedLanguages.includes(lang)) lang = 'en'

        let cookieLang = cookies.get('gw_lang')
        if (cookieLang && cookieLang !== lang) {
            commit('account/setLang', lang)
            await dispatch('translation/getTranslation')
            resolveTranslations()
        }
        let path = router.resolve({
            name: router.name
        })
        if (typeof path === 'undefined') {
            const url = window.location.pathname.toLocaleLowerCase()
            if (url.search(Lang.en | Lang.sk | Lang.pl | Lang.hr)) {
                path = url
                    .split('/')
                    .filter((el) => el)
                    .filter((el) => !supportedLanguages.includes(el))
                    .pop()
            }
        }

        let redirectTo = path.href
            .split('/')
            .filter((el) => el)
            .filter((el) => ![Lang.en, Lang.pl, Lang.sk, Lang.hr].includes(el))
        redirectTo = redirectTo.length ? redirectTo : 'news'

        switch (redirectTo.length) {
            case 1:
                if (
                    !!store.state.account.actionAfterLogin &&
                    router.name !== 'registration-wizard' &&
                    router.name !== 'legalInformation'
                ) {
                    redirectTo = 'registration-wizard'
                } else {
                    redirectTo = `${redirectTo[0]}`
                }
                break
            case 2:
                redirectTo = `${redirectTo[0]}/${redirectTo[1]}`
                break
            case 3:
                redirectTo = `${redirectTo[1]}/${redirectTo[2]}`
                break
            case 0:
            default:
                redirectTo = 'news'
                break
        }

        if (route.query.openChat) {
            await router.push({ path: `/${redirectTo}`, query: { openChat: 'true' } })
            return
        }
        await router.push({ path: `/${redirectTo}` })
    } else if (!store.state.account.lang || store.state.account.lang !== cookies.get('gw_lang')) {
        // try to get selected language from cookie
        let lang = cookies.get('gw_lang')
        if (lang === null) {
            lang = locale in store.state.translation ? locale : import.meta.env.VITE_APP_I18N_LOCALE
        }
        commit('account/setLang', lang)
    }
    if (navigator.userAgent.match(/Safari/)) {
        document.body.classList.add('ios-safari')
    }
    if (
        getters['account/isAuth'] &&
        getters['account/actionAfterLogin'] &&
        router.name !== 'registration-wizard' &&
        router.name !== 'legalInformation'
    ) {
        router.push('/registration-wizard')
    }
})
onMounted(async () => {
    commit(
        'account/setLocale',
        store.state.account.locale ? store.state.account.locale : import.meta.env.VITE_APP_I18N_LOCALE
    )
    // Feature flags
    state.isLocationMap = window.location.pathname.toLowerCase().indexOf('locationmap') > -1
    await dispatch('featureFlags/getFeatures', {
        host: host,
        env: env,
        ID: ID
    })

    // recaptcha
    const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
    if (recaptchaSiteKey) {
        commit('settings/setRecaptchaSiteKey', { recaptchaSiteKey })
    }

    setDefaultCookiesSettings()

    // await dispatch('translation/getTranslation')

    // i18n.setLocaleMessage(store.state.account.lang, store.state.translation[store.state.account.lang])
    // i18n.locale = store.state.account.lang

    // re-initialize sidebar links
    // initializeLinks(store.state.featureFlag, i18n.t)

    // set recaptcha lang in iframe
    // setCaptchaLang(store.state.account.lang)

    // re-initialize - if zoho is not loaded
    const zohoEnv = import.meta.env.VITE_APP_UC_ZOHO
    const zohoSalesEnv = import.meta.env.VITE_APP_UC_ZOHOSALES

    // Usercentrics
    // https://admin.usercentrics.eu/#/v2/configuration/setup
    // https://docs.usercentrics.com/cmp_browser_sdk/4.20.1/index.html
    nextTick(() => {
        UC.init().then((initialUIValues) => {
            const categories = UC.getCategoriesBaseInfo()
            // Zoho Service
            let zohoService = null
            let zohoSalesService = null

            const functionalServices = categories.find((el) => el.slug === 'functional') || null
            if (functionalServices) {
                zohoService = functionalServices.services.find((el) => el.id === zohoEnv) || null
                zohoSalesService = functionalServices.services.find((el) => el.id === zohoSalesEnv) || null
            }
            if (typeof window.$zoho === 'undefined') {
                // re-init $zoho if not available
                initializeZoho(zohoService, zohoSalesService, store)
            }
        })

        document.body.setAttribute('style', cssVariables.value)
        return
    })
})
</script>

<template>
    <v-app
        :class="[
            sidebarExpanded ? 'sidebar-expanded' : '',
            state.isLocationMap && windowWidth < 960 ? 'position-fixed' : ''
        ]"
    >
        <Notify />
        <RouterView />
        <v-btn v-show="state.isToTop" v-scroll="onScroll" class="to-top" color="primary" dark fab @click="toTop">
            <v-icon class="active-icon"> mdi-arrow-up </v-icon>
        </v-btn>
    </v-app>
</template>

<style lang="scss">
@import 'styles/main';
</style>
