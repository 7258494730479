const RegistrationView = Object.freeze({
    personal_details: 'ContactInfo',
    phone_verification: 'PhoneVerification',
    price_plan: 'PriceProgram',
    payment_method: 'PaymentMethod',
    confirmation: 'Confirmation',
    showComponent: (view) => {
        return RegistrationView[view]
    }
})

export { RegistrationView }
