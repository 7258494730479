import 'vuetify/styles'
import { createVuetify } from "vuetify"
import * as allComponents from 'vuetify/lib/components/index'
import * as directives from 'vuetify/lib/directives/index'
import * as labs from 'vuetify/lib/labs/components'
import colors from 'vuetify/lib/util/colors'


import { fa } from 'vuetify/lib/iconsets/fa'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'
import pl from 'vuetify/lib/locale/pl'
import sk from 'vuetify/lib/locale/sk'
import hr from 'vuetify/lib/locale/hr'

import '@mdi/font/css/materialdesignicons.css'

import store from '@/store'
const { state, getters, dispatch } = store

// dispatching get translations here since its first module init from main.ts
// where we have store and router available
await dispatch('translation/getTranslation')
const currentTranslation = getters['translation/currentTranslation']

const vuetify = createVuetify({
    defaults: {
        VDataTable: {
            loadingText: currentTranslation['general.loadingItems'],
            itemsPerPageText: currentTranslation['table.ItemsPerPage']
        },
        VDataTableServer: {
            loadingText: currentTranslation['general.loadingItems'],
            itemsPerPageText: currentTranslation['table.ItemsPerPage']
        }
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
            fa
        }
    },
    lang: {
        locales: { pl, sk, hr },
        current: getters['account/lang']
    },
    theme: {
        defaultTheme: 'light',
        variations: {
            colors: ['color-gray', 'secondary', 'white', 'black', 'text', 'grey', 'text-grey', 'bg-blue'],
            lighten: 2,
            darken: 2,
        },
        options: {
            customProperties: true
        },
        themes: {
            light: {
                dark: false,
                colors: state.themes.light
            }
        }
    },
    components: {
        ...allComponents,
        ...labs
    },
    directives,
    display: {
        xs: 0,
        sm: 340,
        md: 768,
        lg: 960,
        xl: 1280,
    }
})

export default vuetify