<script setup>
import { ref, onMounted, computed } from 'vue'
import moment from 'moment'
import router from '@/router'

// composables
import useCharging from '@/composables/charging/useCharging'
import usePriceFormat from '@/composables/charging/usePriceFormat'
import useSessionSummary from '@/composables/charging/useSessionSummary'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty, isValidEmail } = useValidations()

// translations
const { t } = useI18n()
import { useI18n } from 'vue-i18n'

const {
    elapsedTime,
    sessionSummary,
    connectorData,
    isGuest,
    closeChargingSession,
    blockingUserInput
} = useCharging()

const { pricePerKWH, pricePerMin, sessionPrice } = usePriceFormat()
const {
    receiptEmail,
    pdfReceiptAvailable,
    checkForReceiptAvailability,
    downloadReceipt
} = useSessionSummary()

const RECEIPT_READY_CHECK_INTERVAL = parseInt(import.meta.env.VITE_APP_RECEIPT_READY_CHECK_INTERVAL) || 10000
const promptVisible = ref(false)

const isFormValid = computed(() => {
    return typeof isValidEmail(receiptEmail.value) === 'boolean' && typeof isNotEmpty(receiptEmail.value) === 'boolean'
})

const togglePrompt = (val) => {
    if (!isGuest.value) {
        router.push('locationMap')
        return
    }
    promptVisible.value = val
}

onMounted(async () => {
    if (isGuest.value) {
        await checkForReceiptAvailability()
        let downloadReceiptReadyCheckInterval = null
        if (!pdfReceiptAvailable.value) {
            downloadReceiptReadyCheckInterval = setInterval(async () => {
                await checkForReceiptAvailability()
                if (pdfReceiptAvailable.value) clearInterval(downloadReceiptReadyCheckInterval)
            }, RECEIPT_READY_CHECK_INTERVAL)
        }
    }
})

</script>

<template>
    <v-card
        :elevation="3"
        class="session-summary mr-auto ml-auto mt-md-8 mt-2 d-flex flex-column align-center justify-center text-center"
    >
        <div class="top-bar d-flex justify-center align-center">
            <h6 class="text-subtitle-1 font-weight-medium text-no-wrap text-truncate">
                {{ t('startCharging.sessionSummary') }}
            </h6>
        </div>
        <v-container>
            <v-row class="mb-2">
                <v-col
                    class="text-left"
                    cols="4"
                >
                    <h4 class="text-body-2">
                        {{ t('startCharging.plugDetails') }}
                    </h4>
                </v-col>
                <v-col
                    class="text-right"
                    cols="8"
                >
                    <h4 class="text-body-2 font-weight-medium">
                        {{ `${sessionSummary.connector_type_code.split('_')[1]} plug` }}
                    </h4>
                    <h4
                        v-if="sessionSummary.connector_max_power"
                        class="text-body-2"
                    >
                        {{ `max ${sessionSummary.connector_max_power} kW` }}
                    </h4>
                    <h4
                        v-else
                        class="text-body-2"
                    >
                        max ? kW
                    </h4>
                    <h4 class="text-body-2">
                        {{ pricePerKWH }}/kWh
                    </h4>
                    <h4 class="text-body-2">
                        + {{
            `${pricePerMin}/min (${t('startCharging.after')} ${connectorData.price.postponeMinutes} min)`
        }}
                    </h4>
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="my-2">
                <v-col
                    class="text-left"
                    cols="4"
                >
                    <h4 class="text-body-2">
                        {{ t('startCharging.duration') }}
                    </h4>
                </v-col>
                <v-col
                    class="text-right"
                    cols="8"
                >
                    <h4 class="text-body-2 font-weight-medium">
                        {{
            `${Math.floor(sessionSummary.total_minutes)}min ${Math.ceil((sessionSummary.total_minutes % 1) *
                60)}s`
        }}
                    </h4>
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="my-2">
                <v-col
                    class="text-left"
                    cols="4"
                >
                    <h4 class="text-body-2">
                        {{ t('startCharging.start') }}
                    </h4>
                </v-col>
                <v-col
                    class="text-right"
                    cols="8"
                >
                    <h4 class="text-body-2 font-weight-medium">
                        {{ moment(sessionSummary.charging_from).format('D.M.YYYY, H:mm:ss') }}
                    </h4>
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="my-2">
                <v-col
                    class="text-left"
                    cols="4"
                >
                    <h4 class="text-body-2">
                        {{ t('startCharging.end') }}
                    </h4>
                </v-col>
                <v-col
                    class="text-right"
                    cols="8"
                >
                    <h4 class="text-body-2 font-weight-medium">
                        {{ moment(sessionSummary.charging_to).format('D.M.YYYY, H:mm:ss') }}
                    </h4>
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="my-2">
                <v-col
                    class="text-left"
                    cols="8"
                >
                    <h4 class="text-body-2">
                        {{ t('startCharging.chargeTotal') }}
                    </h4>
                </v-col>
                <v-col
                    class="text-right"
                    cols="4"
                >
                    <h4 class="text-body-2 font-weight-medium">
                        {{ `${sessionSummary.total_kwh} kWh` }}
                    </h4>
                </v-col>
            </v-row>
            <template v-if="sessionSummary.free_kwh !== null">
                <v-divider />
                <v-row class="my-2">
                    <v-col
                        class="text-left"
                        cols="8"
                    >
                        <h4 class="text-body-2">
                            {{ t('startCharging.chargeForFree') }}
                        </h4>
                    </v-col>
                    <v-col
                        class="text-right"
                        cols="4"
                    >
                        <h4 class="text-body-2 font-weight-medium">
                            {{ `${sessionSummary.free_kwh} kWh` }}
                        </h4>
                    </v-col>
                </v-row>
            </template>
            <v-divider />
            <v-row class="my-2">
                <v-col
                    class="text-left"
                    cols="8"
                >
                    <h4 class="text-body-2">
                        {{ t('startCharging.chargePaid') }}
                    </h4>
                </v-col>
                <v-col
                    class="text-right"
                    cols="4"
                >
                    <h4 class="text-body-2 font-weight-medium">
                        {{ `${sessionSummary.total_kwh} kWh` }}
                    </h4>
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="my-2">
                <v-col
                    class="text-left"
                    cols="8"
                >
                    <h4 class="text-body-2">
                        {{ t('startCharging.priceVat') }}
                    </h4>
                </v-col>
                <v-col
                    class="text-right"
                    cols="4"
                >
                    <h4 class="text-body-2 font-weight-medium">
                        {{ sessionPrice }}
                    </h4>
                </v-col>
            </v-row>
            <v-row v-if="isGuest">
                <v-col cols="12">
	                <small v-if="!pdfReceiptAvailable">
		                {{ t('startCharging.downloadReceiptInfo') }}
	                </small>
                    <v-btn
                        :disabled="!pdfReceiptAvailable"
                        :loading="!pdfReceiptAvailable"
                        color="primary"
                        rounded="xl"
                        @click="downloadReceipt()"
                    >
                        {{ t('startCharging.downloadReceipt') }}
                        <template v-slot:loader>
                            <span>{{ t('startCharging.generatingReceipt') }}</span>
                        </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        class="text-white"
                        color="grey-darken-4"
                        rounded="xl"
                        @click="togglePrompt(true)"
                    >
                        {{ t('startCharging.close') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <div
            :class="{ 'visible': promptVisible }"
            class="prompt pa-4"
        >
            <div class="heading mb-4">
                <h3 class="text-subtitle-1 font-weight-medium mb-4">
                    {{ t('startCharging.confirmAction') }}
                </h3>
            </div>
            <div class="content">
                <h4 class="text-body-2 mb-4">
                    {{ t('startCharging.areYouSureSummary') }}
                </h4>
                <v-text-field
                    v-model="receiptEmail"
                    :disabled="blockingUserInput"
                    :label="t('Client.Email')"
                    density="compact"
                    variant="outlined"
                    type="email"
                />
                <div class="buttons d-flex align-center justify-space-around">
                    <v-btn
                        variant="outlined"
                        rounded="xl"
                        @click="togglePrompt(false)"
                    >
                        {{ t('startCharging.back') }}
                    </v-btn>
                    <v-btn
                        :disabled="(receiptEmail !== '') ? !isFormValid : receiptEmail !== ''"
                        :loading="blockingUserInput"
                        color="error"
                        rounded="xl"
                        @click="closeChargingSession()"
                    >
                        {{ t('startCharging.continue') }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-card>
</template>
