<script setup>
import useCharging from '@/composables/charging/useCharging'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const {
    chargingStarted,
    activeScreen,
    backToMap,
    initiateCharging,
    chargingDetails,
    isGuest,
    closeChargingSession,
    errorMessage
} = useCharging()


</script>

<template>
    <v-card
        :elevation="3"
        class="charging-status mr-auto ml-auto mt-md-8 mt-2 pa-4 d-flex flex-column align-center justify-center text-center"
    >
        <div
            :class="{ 'started': chargingStarted }"
            class="stage d-flex align-center justify-center mb-4"
        >
            <svg
                height="84"
                width="39"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.255 24.434.477 48.392l6.391.017 6.392.017v35.197l12.773-23.949c7.024-13.172 12.772-23.972 12.772-24.001 0-.029-2.866-.053-6.37-.053h-6.37l-.016-17.572L26.033.475 13.255 24.434"
                    fill-rule="evenodd"
                />
            </svg>
            <div class="mark d-flex align-center justify-center">
                <span />
            </div>
        </div>
        <template v-if="chargingStarted">
            <h3 class="text-h6 text-center mt-5 mb-4">
                {{ t('startCharging.chargingStarted') }}
            </h3>
            <div class="buttons">
                <v-btn
                    :disabled="chargingDetails === null"
                    class="text-white mb-4"
                    color="rgb(var(--v-theme-sc-blue))"
                    rounded="xl"
                    @click="activeScreen = 3"
                >
                    {{ t('startCharging.showChargingDetails') }}
                </v-btn>
                <v-btn
                    variant="outlined"
                    rounded="xl"
                    @click="backToMap()"
                >
                    {{ t('startCharging.close') }}
                </v-btn>
            </div>
        </template>
        <template v-else>
            <h3 class="text-h6 text-center mt-5 mb-4">
                {{ t('startCharging.chargingNotStarted') }}
            </h3>
            <p
                v-if="errorMessage"
                class="pb-12"
            >
                {{ errorMessage }}
            </p>
            <div class="buttons mt-auto">
                <v-btn
                    class="text-white mb-4"
                    color="red"
                    rounded="xl"
                    @click="initiateCharging()"
                >
                    {{ t('startCharging.tryAgain') }}
                </v-btn>
                <v-btn
                    v-if="isGuest"
                    variant="outlined"
                    rounded="xl"
                    @click="closeChargingSession()"
                >
                    {{ t('startCharging.cancel') }}
                </v-btn>
                <v-btn
                    v-else
                    variant="outlined"
                    rounded="xl"
                    @click="backToMap()"
                >
                    {{ t('startCharging.cancel') }}
                </v-btn>
            </div>
        </template>
    </v-card>
</template>
