import { createRouter, createWebHistory } from 'vue-router'

import { availableLanguages } from '@/configs/index.js'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

import moment from 'moment'
import { ROLES } from '@/constants/roles'

// store
import { store } from '@/store'

import App from '@/App.vue'
import Logout from '@/pages/Logout.vue'
import Home from '@/pages/home/Home.vue'
import News from '@/pages/news/News.vue'
import Help from '@/pages/help/Help.vue'
import Oauth from '@/pages/login/Oauth.vue'
import Login from '@/pages/login/Login.vue'
import Pricing from '@/components/Pricing.vue'
import PageNotFound from '@/pages/404/404.vue'
import Coupons from '@/pages/coupons/Coupons.vue'
import Account from '@/pages/account/Account.vue'
import Welcome from '@/pages/welcome/Welcome.vue'
import Drivers from '@/pages/drivers/Drivers.vue'
import Invoices from '@/pages/invoices/Invoices.vue'
import Charging from '@/pages/charging/Charging.vue'
import Sessions from '@/pages/sessions/Sessions.vue'
import AddDriver from '@/pages/drivers/AddDriver.vue'
import Dashboard from '@/pages/dashboard/Dashboard.vue'
import Campaigns from '@/pages/campaigns/Campaigns.vue'
import LocationMap from '@/pages/locationMap/LocationMap.vue'
import DriverDetails from '@/pages/drivers/DriverDetails.vue'
import Registration from '@/pages/registration/Registration.vue'
import FleetDashboard from '@/pages/dashboard/FleetDashboard.vue'
import ResetPassword from '@/pages/resetPassword/ResetPassword.vue'
import ChargingCards from '@/pages/chargingCards/ChargingCards.vue'
import EditDriverPricing from '@/pages/drivers/EditDriverPricing.vue'
import PasswordRecovery from '@/pages/resetPassword/PasswordRecovery.vue'
import ChargerDetails from '@/pages/chargerManagement/ChargerDetails.vue'
import LocationDetails from '@/pages/chargerManagement/LocationDetails.vue'
import LegalInformation from '@/pages/legalInformation/LegalInformation.vue'
import ChargerManagement from '@/pages/chargerManagement/ChargerManagement.vue'
import ConsumptionDetails from '@/pages/chargerManagement/ConsumptionDetails.vue'
import RegistrationWizard from '@/pages/registrationWizard/RegistrationWizard.vue'
import LiveChat from './pages/liveChat/LiveChat.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:urlLang()?',
            props: true,
            meta: {
                requiredAuth: false,
                roles: ['all']
            },
            component: App,
            children: [
                {
                    path: '',
                    meta: {
                        requiredAuth: true,
                        roles: ['all']
                    },
                    component: Home,
                    children: [
                        {
                            path: '/:urlLang()?/locationMap',
                            name: 'locationMap',
                            meta: {
                                requiredAuth: false,
                                roles: ['all']
                            },
                            component: LocationMap
                        },
                        {
                            path: '/:urlLang()?/dashboard',
                            name: 'dashboard',
                            meta: {
                                requiredAuth: false,
                                roles: ['all']
                            },
                            component: Dashboard
                        },
                        {
                            path: '/:urlLang()?/fleet-dashboard',
                            name: 'fleetDashboard',
                            meta: {
                                requiredAuth: false,
                                roles: ['all']
                            },
                            component: FleetDashboard
                        },
                        {
                            path: '/:urlLang()?/news',
                            name: 'news',
                            props: true,
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: News
                        },
                        {
                            path: '/:urlLang()?/sessions',
                            name: 'sessions',
                            props: true,
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: Sessions
                        },
                        {
                            path: '/:urlLang()?/account',
                            name: 'account',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: Account
                        },
                        {
                            path: '/:urlLang()?/help',
                            name: 'help',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: Help
                        },
                        {
                            path: '/:urlLang()?/price-programs',
                            name: 'pricePrograms',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: Pricing
                        },
                        {
                            path: '/:urlLang()?/drivers',
                            name: 'drivers',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: Drivers
                        },
                        {
                            path: '/:urlLang()?/drivers/add',
                            name: 'add-driver',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: AddDriver
                        },
                        {
                            path: '/:urlLang()?/drivers/:id',
                            name: 'driverDetails',
                            props: true,
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: DriverDetails
                        },
                        {
                            path: '/:urlLang()?/driver-price-program/:id',
                            name: 'driverPriceProgram',
                            props: true,
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: EditDriverPricing
                        },
                        {
                            path: '/:urlLang()?/invoices',
                            name: 'invoices',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: Invoices
                        },
                        {
                            path: '/:urlLang()?/charger-management',
                            name: 'chargerManagement',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: ChargerManagement
                        },
                        {
                            path: '/:urlLang()?/charger/:id',
                            name: 'chargerDetails',
                            props: true,
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: ChargerDetails
                        },
                        {
                            path: '/:urlLang()?/consumption/:id',
                            name: 'consumptionDetails',
                            props: true,
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: ConsumptionDetails
                        },
                        {
                            path: '/:urlLang()?/location/:id',
                            name: 'locationDetails',
                            props: true,
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: LocationDetails
                        },
                        {
                            path: '/:urlLang()?/coupons',
                            name: 'coupons',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: Coupons
                        },
                        {
                            path: '/:urlLang()?/charging-cards',
                            name: 'chargingCards',
                            meta: {
                                requiredAuth: true,
                                roles: ['all']
                            },
                            component: ChargingCards
                        }
                    ]
                },
                {
                    path: '/:urlLang()?/register/:type',
                    redirect: { name: 'registration' }
                },
                {
                    path: '/:urlLang()?/registration',
                    name: 'registration',
                    props: true,
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: Registration
                },
                {
                    path: '/:urlLang()?/registration-wizard',
                    name: 'registration-wizard',
                    props: true,
                    meta: {
                        requiredAuth: true,
                        roles: ['all']
                    },
                    component: RegistrationWizard
                },
                {
                    path: '/:urlLang()?/legal-information',
                    name: 'legalInformation',
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: LegalInformation
                },
                {
                    path: '/:urlLang()?/promo/:campaign',
                    redirect: { name: 'campaign' }
                },
                {
                    path: '/:urlLang()?/campaign',
                    name: 'campaign',
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: Campaigns
                },
                {
                    path: '/:urlLang()?/login',
                    name: 'login',
                    props: true,
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: Login
                },
                {
                    path: '/:urlLang()?/oauth',
                    name: 'oauth',
                    props: true,
                    meta: {
                        requiredAuth: true,
                        roles: ['all']
                    },
                    component: Oauth
                },
                {
                    path: '/:urlLang()?/logout',
                    name: 'logout',
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: Logout
                },
                {
                    path: '/:urlLang()?/reset-password',
                    name: 'reset-password',
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: ResetPassword
                },
                {
                    path: '/:urlLang()?/charging',
                    name: 'charging',
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: Charging
                },
                {
                    path: '/:urlLang()?/password-recovery',
                    name: 'password-recovery',
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: PasswordRecovery
                },
                {
                    path: '/:urlLang()?/welcome',
                    name: 'welcome',
                    meta: {
                        requiredAuth: false,
                        roles: ['all']
                    },
                    component: Welcome
                }
            ]
        },
        {
            path: '/:catchAll(.*)*',
            name: 'pageNotFound',
            component: PageNotFound,
            meta: {
                requiredAuth: false,
                roles: ['all']
            }
        },
        {
            path: '/live-chat',
            name: 'LiveChat',
            meta: {
                requiredAuth: false,
                roles: ['all']
            },
            component: LiveChat
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    const { dispatch } = store
    const DOMAIN = import.meta.env.VITE_APP_COOKIES_DOMAIN

    if (cookies.get('token_urlBearer') === 'true') {
        const pathSplit = to.fullPath.split('/')
        if (pathSplit.length > 2 && !pathSplit.some((el) => availableLanguages.includes(el))) {
            const availableRedirects = ['account', 'invoices', 'registration']
            const redirectTo = pathSplit.find((el) => availableRedirects.includes(el)) || 'account'
            next({ name: redirectTo })
        }
    }

    router.prev = from
    let isUserAuthorized = !!cookies.get('gw_token')
    const isPageAuthRequired = to.matched.some((it) => it.meta.requiredAuth)

    // check if we need to request new token
    if (cookies.get('gw_token')) {
        const rememberMe = store.state.account.remember_me
        const refreshTokenDays = import.meta.env.VITE_APP_OAUTH_REMEMBER_ME_REFRESH_TOKEN_DAYS || 30

        if (rememberMe) {
            const difference = moment(store.state.account.expires_at).diff(moment(), 'days', false)
            if (difference > 0 && difference <= parseInt(refreshTokenDays)) {
                // fetch refresh token
                // save token to cookies and vuex -> will be done in action via commit auth
                await dispatch('account/refreshToken', rememberMe)

                // continue :)
                window.location.href = to.name
            }
        }
    }

    // check if driver account can see requested page
    if (
        ROLES.driver[to.name] &&
        ROLES.driver[to.name].read === false &&
        store.state.account.roles?.length === 1 &&
        store.state.account.roles[0] === 'driver'
    ) {
        next({ name: 'pageNotFound' })
    }

    if (isPageAuthRequired && !isUserAuthorized) {
        if (!cookies.isKey('redirect_after_login') && to.name) {
            cookies.set('redirect_after_login', to.fullPath, 60 * 60, '/', DOMAIN)
        }
        next({ name: 'welcome' })
    }
    // for pages that required special role
    if (to.matched.some((it) => !it.meta?.roles?.includes('all'))) {
        if (to.meta.roles.includes(store.state.account.role)) {
            next({ name: 'home' })
        }
    }
    next()
})

export default router
