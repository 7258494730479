<script setup>
import { ref, onBeforeMount, watch } from 'vue'
import store from '@/store'

// utils
import markdownParser from '@/utils/markdownParser'
import { PaymentProviders } from '@/utils/paymentProviders'

// components
import Braintree from './payments/Braintree.vue'
import Popup from '@/components/modals/Popup.vue'
import PaymentCard from '@/components/PaymentCard.vue'
import InfoMessage from '@/components/InfoMessage.vue'
import BankTransfer from './payments/BankTransfer.vue'

// composables
import usePayout from '@/composables/usePayout'
import useAccountManagePayment from '@/composables/account/useAccountManagePayment'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { getters, dispatch } = store

// Constants
const VERIFY_AMOUNT = import.meta.env.VITE_APP_BRAINTREE_VERIFY_AMOUNT
const PENDING_CHECKOUT_INTERVAL = import.meta.env.VITE_APP_PENDING_CHECKOUT_INTERVAL

const {
    paymentInfo,
    removeCard,
    instance,
    isPopupAskDelete,
    popupData,
    paymentGatewayProvider,
    currency,
    saveBrainTreeCard
} = useAccountManagePayment()

const { checkStatus, addPaymentCard, checkoutStatus, checkingStatus } = usePayout()

const interval = ref(null)

watch(checkoutStatus, (val) => {
    if (val === 'pending') {
        interval.value = setInterval(() => {
            checkStatus()
            if (getters['account/paymentInfo'].provider) clearInterval(interval.value)
        }, PENDING_CHECKOUT_INTERVAL)
        return
    }
    clearInterval(interval.value)
    dispatch('account/getPaymentInfo')
})

const showPopup = () => {
    isPopupAskDelete.value = true
}

onBeforeMount(async () => {
    if (
        PaymentProviders.Payout === paymentGatewayProvider.value ||
        PaymentProviders.Mock === paymentGatewayProvider.value
    )
        checkStatus()
})
</script>

<template>
    <div class="payment-info">
        <v-row v-if="paymentInfo && paymentInfo.provider">
            <v-col v-if="PaymentProviders.BankTransfer === paymentInfo.provider">
                <BankTransfer />
            </v-col>
            <v-col v-else class="py-8">
                <PaymentCard :payment-info="paymentInfo" @showPopup="showPopup" />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col
                v-if="
                    PaymentProviders.Payout === paymentGatewayProvider ||
                    PaymentProviders.Mock === paymentGatewayProvider
                "
                class="py-8"
            >
                <v-btn
                    :loading="getters['isLoading'] || checkingStatus"
                    color="primary"
                    class="d-flex mx-auto"
                    elevation="2"
                    @click="addPaymentCard"
                >
                    <v-icon class="mr-1"> mdi-credit-card </v-icon>
                    {{ t('account.AddPaymentCard') }}
                </v-btn>
                <h4 v-if="checkoutStatus === 'pending'" class="text-center pt-4">
                    {{ t('account.pendingCheckoutMessage') }}
                </h4>
            </v-col>
            <v-col v-else>
                <Braintree :instance="instance" :payment-info="paymentInfo" @saveCard="saveBrainTreeCard" />
            </v-col>
        </v-row>
        <Popup
            :popup-data="popupData.questionDelete"
            :active="isPopupAskDelete"
            :loading="store.getters['isLoading']"
            @cancel="isPopupAskDelete = false"
            @accept="removeCard"
        />
        <div class="mt-6 mb-6" v-if="!paymentInfo.provider">
            <InfoMessage v-if="PaymentProviders.Braintree === paymentGatewayProvider">
                <div
                    v-html="
                        markdownParser(
                            t('managePayment.withdrawalInfoMessage', { amount: `${VERIFY_AMOUNT}${currency}` })
                        )
                    "
                ></div>
            </InfoMessage>
            <InfoMessage
                v-if="
                    PaymentProviders.Payout === paymentGatewayProvider ||
                    PaymentProviders.Mock === paymentGatewayProvider
                "
            >
                <div v-html="markdownParser(t('managePayment.withdrawalInfoMessagePayout'))"></div>
            </InfoMessage>
            <InfoMessage v-if="paymentGatewayProvider === PaymentProviders.Braintree" class="mt-6 mb-12">
                <div v-html="markdownParser(t('managePayment.providingCardDetails'))"></div>
            </InfoMessage>
            <InfoMessage
                v-if="
                    PaymentProviders.Payout === paymentGatewayProvider ||
                    PaymentProviders.Mock === paymentGatewayProvider
                "
                class="mt-6 mb-12"
            >
                <div v-html="markdownParser(t('managePayment.providingCardDetailsPayout'))"></div>
            </InfoMessage>
        </div>
        <template v-if="paymentInfo.provider && paymentInfo.provider !== PaymentProviders.BankTransfer">
            <InfoMessage v-if="paymentInfo.provider === PaymentProviders.Braintree" class="mt-6 mb-12">
                <div v-html="markdownParser(t('managePayment.providingCardDetails'))"></div>
            </InfoMessage>
            <InfoMessage
                v-if="
                    PaymentProviders.Payout === paymentGatewayProvider ||
                    PaymentProviders.Mock === paymentGatewayProvider
                "
                class="mt-6 mb-12"
            >
                <div v-html="markdownParser(t('managePayment.providingCardDetailsPayout'))"></div>
            </InfoMessage>
        </template>
    </div>
</template>
