<script setup>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'

// components
import ActionPrompt from '@/pages/chargingCards/components/lists/ActionPrompt.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { getters } = store

const props = defineProps({
    driverId: {
        type: Number,
        default: null
    }
})

const {
    showActionPrompt,
    actionPromptVisible,
    selectedAction,
    fetchingData,
    driverDetailId,
    driverDetailCard,
    selectedDriver
} = useChargingCards()

driverDetailId.value = props.driverId
const card = ref(null)
const isPayer = ref(getters['account/roles'].find(role => role === 'payer'))

const driversChargingCard = computed(() => {
    if (getters['drivers/driver'].cards) {
        card.value = getters['drivers/driver'].cards
        if (card.value.length > 1) {
            return card.value.filter(y => y.is_active === true)[0]
        }
        return card.value[0]
    }
})

const addNewCardDialog = () => {
    selectedAction.value = 'addNewCard'
    selectedDriver.value = props.driverId
    actionPromptVisible.value = true
}

const blockDriverCardDialog = () => {
    selectedAction.value = 'blockDriverCard'
    actionPromptVisible.value = true
    driverDetailCard.value = driversChargingCard.value
}

const unblockDriverCardDialog = () => {
    selectedAction.value = 'unblockDriverCard'
    actionPromptVisible.value = true
    driverDetailCard.value = driversChargingCard.value
}
</script>

<template>
    <section class="charging-card-new">
        <v-row>
            <v-col
                class="pt-0 mb-3"
                cols="12"
            >
                <h2 class="home-page-title">
                    {{ t('chargingCards.pageTitle') }}
                </h2>
            </v-col>
        </v-row>
        <v-card
            :class="{ 'd-flex align-items-center justify-center text-center': !!!driversChargingCard }"
            class="charging-card-card pa-5"
            elevation="0"
        >
            <v-row>
                <v-col
                    v-if="!!!driversChargingCard"
                    cols="12"
                >
                    <v-btn
                        :disabled="!!driversChargingCard"
                        class="text-white mb-2 mb-sm-0"
                        color="var(--v-menu-background)"
                        size="large"
                        @click="addNewCardDialog"
                        data-testid="addNewRfid"
                    >
                        <v-icon class="mr-2">
                            mdi-credit-card
                        </v-icon>
                        {{ t('chargingCards.addNewCard') }}
                    </v-btn>
                </v-col>
            </v-row>
            <template v-if="driversChargingCard">
                <v-row>
                    <v-col
                        class="pt-4"
                        cols="12"
                    >
                        <div class="card-wrapper d-flex justify-center pt-4 pb-8">
                            <div class="card d-flex">
                                <div class="qr-wrapper d-flex justify-center pr-5">
                                    <div class="qr d-sm-block">
                                        <v-img
	                                        src="/assets/icons/rfid.svg"
                                            width="90px"
                                        />
                                    </div>
                                </div>

                                <div class="content pt-4">
                                    <div class="data pl-8">
                                        <template v-if="driversChargingCard">
                                            <div class="card-number">
                                                <span class="text-body-2">No.</span>
                                                <h4
                                                    v-if="driversChargingCard"
                                                    class="text-h4"
                                                    data-testid="rfidNumber"
                                                >
                                                    {{ driversChargingCard.card_number }}
                                                </h4>
                                                <h4
                                                    v-else
                                                    class="text-h3"
                                                    data-testid="rfidNumber"
                                                >
                                                    -
                                                </h4>
                                            </div>
                                            <div class="card-status mt-3">
                                                <span class="text-body-2">Status</span>
                                                <h4 class="text-body-2 d-flex align-center"
                                                    data-testid="rfidStatus">
                                                    <v-icon
                                                        v-if="driversChargingCard.is_active"
                                                        class="mr-1"
                                                        color="var(--v-primary)"
                                                    >
                                                        mdi-lock-open
                                                    </v-icon>
                                                    <v-icon v-else>
                                                        mdi-lock
                                                    </v-icon>
                                                    {{ driversChargingCard.status }}
                                                </h4>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="card-number">
                                                <span class="text-body-2">No.</span>
                                                <h4 class="text-h4">
                                                    -
                                                </h4>
                                            </div>
                                            <div class="card-status">
                                                <span class="text-body-2">Status</span>
                                                <h4 class="text-body-2 d-flex align-center">
                                                    {{ t('chargingCards.noStatus') }}
                                                </h4>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <div
                                v-if="driversChargingCard"
                                class="buttons d-flex justify-space-around align-items-center"
                            >
                                <v-btn
                                    v-if="driversChargingCard.is_active"
                                    :disabled="fetchingData"
                                    class="font-weight-regular text-none"
                                    color="primary"
                                    @click="blockDriverCardDialog"
                                    data-testid="blockRfid"
                                >
                                    <v-icon small>
                                        mdi-lock
                                    </v-icon>
                                    {{ t('chargingCards.blockCard') }}
                                </v-btn>
                                <v-btn
                                    v-else
                                    :disabled="fetchingData"
                                    class="font-weight-regular text-none"
                                    color="primary"
                                    @click="unblockDriverCardDialog"
                                    data-testid="unblockRfid"
                                >
                                    <v-icon small>
                                        mdi-lock-open
                                    </v-icon>
                                    {{ t('chargingCards.unblockCard') }}
                                </v-btn>
                                <v-btn
                                    v-if="isPayer"
                                    :disabled="fetchingData"
                                    class="font-weight-regular text-none delete-card"
                                    variant="plain"
                                    @click="showActionPrompt('removeDriverCard', driversChargingCard)"
                                    data-testid="removeRfid"
                                >
                                    <v-icon small>
                                    mdi-delete
                                </v-icon>
                                <span class="pl-2">
                                    {{ t('chargingCards.removeCard') }}
                                </span>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </template>
        <ActionPrompt />
    </v-card>
</section></template>