const ConnectorStatus = Object.freeze({
    Available: 'available',
    Charging: 'charging',
    Unknown: null,
    isAvailable: (status) => {
        return (ConnectorStatus.Available === status || ConnectorStatus.Unknown === status) ? true : false
    },
    isCharging: (status) => {
        return ConnectorStatus.Charging === status
    }
})

export {
    ConnectorStatus
}
